.clear{
  padding: 20px;

  &__header {
    margin-bottom: 20px;

    .back {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  h4{
    margin-bottom: 20px;
  }

  .check__dop svg{
    width: 100%;
    height: 100%;
  }

  .bottons{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}