@use './mixins';

.search-sec {
  background-color: #f8fafc;

  &__wrap {
    padding: 46px 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 176px;

    .search {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }
  }

  @media only screen and (max-width: 1199px) {
    &__wrap {
      padding: 24px 32px;
    }
  }

  @media only screen and (max-width: 1023px) {
    &__wrap {
      display: block;
    }
  }

  @media only screen and (max-width: 767px) {
    &__wrap {
      padding: 24px 18px;
    }
  }
}

.search {
  position: relative;

  &__input {
    @include mixins.input-reset;
    font-size: 18px;
    height: 64px;
    background: #fff;
    border-radius: 5px;
    padding: 0 32px;
    flex-grow: 1;

    &::-webkit-input-placeholder {
      opacity: 0.7;
      color: #4b5876;
    }

    &::-moz-placeholder {
      opacity: 0.7;
      color: #4b5876;
    }

    &:-moz-placeholder {
      opacity: 0.7;
      color: #4b5876;
    }

    &:-ms-input-placeholder {
      opacity: 0.7;
      color: #4b5876;
    }

    &:placeholder {
      opacity: 0.7;
      color: #4b5876;
    }
  }

  &__submit {
    width: 167px;
    height: 50px;
    line-height: 64px;
    //position: absolute;
    //top: 0;
    //right: 0;

    &-icn {
      width: 20px;
      height: 20px;
      margin-right: 16px;
      position: relative;
      top: -2px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: #fff;
      }
    }
  }

  &__update {
    margin-left: 10px;
  }

  @media only screen and (max-width: 767px) {
    &__input {
      font-size: 16px;
      height: 42px;
      padding: 0 18px;
    }

    &__submit {
      position: static;
      width: 100%;
      height: 42px;
      line-height: 42px;
      margin-top: 16px;

      &-icn {
        width: 18px;
        height: 18px;
        margin-right: 12px;
        top: -1px;
      }
    }
  }
}

.search-result {
  width: 417px;
  padding-right: 72px;
  flex-shrink: 0;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
    font-weight: 500;
    line-height: 24px;
    color: #4b5876;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    white-space: nowrap;
    font-size: 18px;
    color: #4b5876;
    opacity: 0.5;
    margin-right: 24px;
  }

  &__val {
    font-size: 24px;
  }

  @media only screen and (max-width: 1199px) {
    width: 320px;
    padding-right: 32px;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    padding: 0;
    margin-top: 32px;
  }

  @media only screen and (max-width: 767px) {
    &__row {
      margin-bottom: 18px;
      line-height: 20px;
    }

    &__name {
      font-size: 16px;
      margin-right: 16px;
    }

    &__val {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
