.check {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;

	&.disabled {
		cursor: default;
	}

	&__dop {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		min-width: 22px;
		height: 22px;
		//border-radius: 50%;
		margin-right: 7px;
		border-color: rgba(96, 100, 112, 0.5);
        border-style: solid;
      border-width: 1px;
		opacity: 0.5;
		transition: opacity 0.35s;

		svg {
          opacity: 0;
          width: 100%;
          height: 100%;
			//width: 7px;
			//height: 5px;
			//position: relative;
			//left: -0.5px;
			//top: -0.5px;
			//fill: white;
		}
	}

	&__txt {
		font-size: 14px;
		line-height: 1.2;
		padding: 6px 0;
		align-items: flex-start;
		color: rgba(96, 100, 112, 0.5);
		transition: color 0.35s;
	}

	input {
		display: none;

		&:checked + .check__dop {
			opacity: 1;

          svg{
            opacity: 1;
          }
		}

		&:checked ~ .check__txt {
			color: #606470;
		}
	}
}
