@use '../../scss/mixins';

.sign {
	background: url(./sign-min.jpg) center center no-repeat #250530;
	background-size: cover;
	min-height: 100vh;
	padding: 60px 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	&__wrap {
		width: 100%;
		max-width: 914px;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}

	&__bar {
		width: 50%;
		background-color: rgba(79, 21, 98, 0.4);
		box-shadow: 0 25px 44px rgba(0, 0, 0, 0.7);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		padding: 120px 40px 120px 36px;
		color: #fff;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0.4;
		}
	}

	&__logo {
		position: absolute;
		top: 70px;
		left: 37px;
		width: 136px;

		svg {
			width: 136px;
			height: 33px;
		}
	}

	&__title {
		margin-bottom: 4px;
		font-weight: 400;
		font-size: 64px;
		position: relative;
		width: 100%;
	}

	&__txt {
		font-size: 18px;
		line-height: 1.33;
		position: relative;
		width: 100%;
	}

	@media only screen and (max-width: 991px) {
		padding: 60px 20px;

		&__bar {
			padding: 120px 32px;
		}

		&__title {
			font-size: 48px;
		}

		&__txt {
			font-size: 16px;
		}
	}

	@media only screen and (max-width: 767px) {
		background: #fff;
		padding: 0;
		display: block;

		&__wrap {
			display: block;
		}

		&__bar {
			background-color: #250530;
			width: 100%;
			display: block;
			padding: 40px 20px;
			text-align: center;
		}

		&__logo {
			position: relative;
			top: 0;
			left: 0;
			margin: 0 auto 20px;
		}
	}

	.lang{
		position: absolute;
		right: 10px;
		top: 10px
	}
}

.sign-form {
	position: relative;
	z-index: 2;
	width: 50%;
	background-color: #fff;
	padding: 70px 40px 50px 56px;

  &.preloader{
    align-self: center;
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 50%;
  }

	&__header {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 30px;

      &.row{
        flex-direction: row;
        justify-content: space-between;

        a{
          font-size: 14px;
          color: #115a6b;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      h2{
        margin-bottom: 20px;
      }

		&-link {
			font-size: 14px;
			color: #115a6b;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__title {
		font-size: 16px;
		line-height: 19px;
		color: #115a6b;
		font-weight: 400;
	}

	&__back {
		display: inline-block;
		margin-bottom: 30px;
		font-size: 16px;
		line-height: 19px;
		color: #115a6b;
		font-weight: 400;

		&-icn {
			display: inline-block;
			vertical-align: middle;
			width: 15px;
			height: 15px;
			margin-right: 16px;
			font-size: 0;
			transition: transform 0.25s;

			svg {
				width: 15px;
				height: 14px;
				fill: transparent;
			}
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		&:hover {
			.sign-form__back-icn {
				transform: translateX(-10px);
			}
		}
	}

	&__input {
		@include mixins.input-reset;
		width: 100%;
		max-width: 310px;
		margin-bottom: 16px;
		height: 32px;
		border-bottom: 1px solid #88acb5;
		color: #115a6b;
		font-size: 14px;

		&::-webkit-input-placeholder {
			opacity: 1;
			color: #88acb5;
		}

		&::-moz-placeholder {
			opacity: 1;
			color: #88acb5;
		}

		&:-moz-placeholder {
			opacity: 1;
			color: #88acb5;
		}

		&:-ms-input-placeholder {
			opacity: 1;
			color: #88acb5;
		}

		&::placeholder {
			opacity: 1;
			color: #88acb5;
		}
		/*webkit autocomplit input*/
		&:-webkit-autofill {
			-webkit-box-shadow: inset 0 0 0 100px #fff !important;
			-webkit-text-fill-color: #115a6b !important;
			color: #115a6b !important;
		}

		&.value,
		&:hover,
		&:focus {
			border-bottom-color: #115a6b;
		}

		&.error {
			border-bottom-color: #ff012f;
		}

		&:focus:required:valid {
			color: #115a6b;
		}

		&:required:valid {
			color: #115a6b;
		}

		&.valid {
			background: url(../../assets/svgSprite/sign-check.svg) right center no-repeat;
		}
	}

	&__sub,
	&__txt {
		margin-top: 20px;
		font-size: 14px;
		color: #88acb5;

		a {
			color: #115a6b;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__sub {
		text-align: center;
	}

	&__error {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		color: #ff012f;
		font-size: 14px;
		padding: 8px 0;
		min-height: 83px;
	}

	&__submit {
		margin: 0 auto;
		width: 100%;
		max-width: 310px;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		color: #fff;
		background: rgba(123, 11, 113, 0.8);

		&:hover {
			background-color: rgba(123, 11, 113, 1);
		}
	}

	@media only screen and (max-width: 991px) {
		padding: 50px 32px;

		&__input,
		&__submit {
			max-width: 100%;
		}
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
		padding: 40px 20px;
		flex-grow: 1;
	}

  .input-text{
    margin-bottom: 20px;
  }

  .wait{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
