.filter {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .filter__buttons {
    margin-left: 100px;
    align-items: center;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 80px;
    }

    .svg_left {
      margin-left: 5px;
    }

    .split {
      color: #4b5876;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 20px;
      margin-right: 10px;
    }

    //svg {
    //	width: 40px;
    //	height: 50px;
    //}
  }

  &__row {
    flex-direction: row;
    display: flex;
    align-items: center;

    .styled-select {
      min-width: 100px;
      margin-right: 20px;
    }

    &.margin {
      margin-top: 10px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    a {
      margin-right: 10px;
      cursor: pointer;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .info {
    display: flex;
    flex: 1;
    font-size: 20px;

    &.top {
      margin-top: 20px;
    }

    .title {
      opacity: 0.6;
      font-weight: 500;
      margin-right: 20px;
    }

    .value {
      font-weight: bold;
    }

    .styled-select {
      .select-items {
        min-width: 200px;
      }
    }

    &__filter {
      align-self: stretch;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__button {
    position: relative;

    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      width: 80px;

      svg {
        //margin-right: 10px;
        width: 32px;
        height: 34px;
      }
    }

    .menu {
      background-color: white;
      position: absolute;
      z-index: 1000;
      display: none;

      li {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        width: 200px;

        svg {
          margin-right: 10px;
        }

        &:hover {
          background-color: #d1d8f5;
        }
      }
    }

    &:hover {
      .menu {
        display: block;
      }
    }
  }

  .select-selected {
    min-width: 150px;
    margin-right: 10px;
  }

  &-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;

    .btn {
      margin-bottom: 5px;
    }
  }
}

.list-table {
  td {
    .to-clear {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }

      .btn:first-child {
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .search-sec__wrap.filter {
    padding: 46px 72px;
  }
}
