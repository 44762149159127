.main-sec {
	background-color: #f8fafc;

	&__wrap {
		padding: 24px 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1368px;
		margin: 0 auto;
	}

	@media only screen and (max-width: 1199px) {
		&__wrap {
			padding: 24px 32px;
		}
	}

	@media only screen and (max-width: 991px) {
		&__wrap {
			display: block;
		}
	}

	@media only screen and (max-width: 767px) {
		&__wrap {
			padding: 24px 18px;
		}
	}
}

.barcode {
	width: 442px;
	flex-shrink: 0;
	background: #fff;
	border: 1px solid #6b1fb2;
	border-radius: 24px;
	text-align: center;
	padding: 26px 24px 40px;
	margin-right: 72px;

	&__title {
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		&-code {
			width: 32px;
			height: 32px;
			margin-right: 16px;
			position: relative;
			top: -2px;

			svg {
				width: 32px;
				height: 32px;
			}
		}

		&-txt {
			font-size: 24px;
			line-height: 1;
			color: #504c4c;
			font-weight: 400;
		}
	}

	&__val {
		font-size: 48px;
		line-height: 1;
		color: #111;
		width: 365px;
		border: none;
		text-align: center;
		outline: none;
	}

	@media only screen and (max-width: 1199px) {
		margin-right: 0;
		padding-bottom: 26px;
		width: calc(50% - 16px);
	}

	@media only screen and (max-width: 991px) {
		max-width: 100%;
		width: 442px;
		margin: 0 auto 16px;
	}

	@media only screen and (max-width: 767px) {
		padding: 24px;

		&__title {
			margin-bottom: 16px;

			&-code {
				width: 24px;
				height: 24px;
				margin-right: 12px;
			}

			&-txt {
				font-size: 18px;
			}
		}

		&__val {
			font-size: 32px;
		}
	}
}

.alert {
	flex-grow: 1;
	border-radius: 24px;
	padding: 16px 26px;
	text-align: center;

	&--ready {
		background-color: #9c9c9c;
	}

	&--error {
		background: linear-gradient(180deg, #ff6f6f 0%, #f91f1f 100%);
	}

	&--warning {
		background: linear-gradient(180deg, #ffce76 0%, #f5d04d 100%);
	}

	&--success {
		background: linear-gradient(180deg, #b5d17d 0%, #6dab67 100%);
	}

	&__row {
		margin: 0 auto 30px;
		height: 184px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 424px;
	}

	&__txt {
		font-size: 36px;
		line-height: 32px;
		color: #fff;
	}

	&__icn {
		svg {
			width: 72px;
			height: 72px;
			fill: transparent;
		}

		&--big {
			svg {
				width: 258px;
				height: 258px;
				fill: transparent;
			}
		}
	}

	@media only screen and (max-width: 1199px) {
		width: calc(50% - 16px);
		flex-grow: unset;
	}

	@media only screen and (max-width: 991px) {
		width: 100%;
	}

	@media only screen and (max-width: 767px) {
		padding: 16px 24px;

		&__row {
			margin: 0 auto 6px;
			height: 130px;
		}

		&__txt {
			font-size: 24px;
			line-height: 1;
		}
	}
}
