.sidebar {
	border-right: 1px solid rgba(75, 88, 118, 0.2);
	width: 72px;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 201;
	background-color: #fff;
	padding: 0 0 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	&__header {
		height: 72px;
		border-bottom: 1px solid rgba(75, 88, 118, 0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		svg {
			display: block;
			fill: #6b1fb2;
			width: 24px;
			height: 24px;
		}
	}

	@media only screen and (max-width: 1199px) {
		padding-bottom: 32px;
	}

	@media only screen and (max-width: 767px) {
		border-right: none;
		border-bottom: 1px solid rgba(75, 88, 118, 0.2);
		width: 100%;
		height: 60px;
		padding: 0;
		flex-direction: row;

		&__header {
			height: 100%;
			border-bottom: none;
			border-right: 1px solid rgba(75, 88, 118, 0.2);
			width: 61px;
		}
	}
}

.sidebar-tools {
	ul {
		li {
			margin: 72px auto 0;
			width: 24px;
		}
	}

	@media only screen and (max-width: 1199px) {
		ul {
			li {
				margin: 32px auto 0;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		height: 100%;

		ul {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 100%;

			li {
				margin: 0;
				width: 61px;
				height: 100%;
				border-left: 1px solid rgba(75, 88, 118, 0.2);
			}
		}
	}
}

.sidebar-tool {
	width: 24px;
	height: 24px;
	display: block;

	svg {
		stroke: #6b1fb2;
		width: 24px;
		height: 24px;
		fill: transparent;
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
