@use './mixins';

/*baisic*/
html {
  -webkit-locale: 'ru-RU';
  direction: ltr;
  unicode-bidi: isolate;
  min-height: 100%;
}

html,
body {
  min-height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  font: 400 16px/1.2 'Gilroy';
  color: #4b5876;
  background: #fff;
  min-width: 310px;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
  opacity: 1;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

/*clearfix*/
.clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

/*box-sizing*/
*,
*:before,
*:after,
input[type='search'] {
  box-sizing: border-box;
}

/*buttons*/
.btn {
  @include mixins.btn-reset;

  &--viol {
    background: linear-gradient(180deg, #9577f6 0%, #6b1fb2 100%), #6b1fb2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    //font-weight: 600;
    font-size: 18px;

    &:hover {
      background: linear-gradient(180deg, #6b1fb2 0%, #9577f6 100%), #6b1fb2;
    }
  }

  &--green {
    background: linear-gradient(180deg, #71e375 0%, #55ae58 100%), #55ae58;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      background: linear-gradient(180deg, #55ae58 0%, #71e375 100%), #55ae58;
    }
  }

  &--q{
    display: block;
    padding: 0;
    outline: 0!important;
    outline-color: transparent!important;
    outline-width: 0!important;
    outline-style: none!important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    border: none;
    background: none;
    box-shadow: none;
    transition: .25s ease;
    cursor: pointer;
    border-radius: 0;
    white-space: nowrap;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    svg{
      width: 26px;
      height: 26px;
      fill: none;
    }
  }

  &--primary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 13px;
    gap: 8px;
    background-color: #F1F5F9;
    box-shadow: 2px 2px 8px #C9D9E8, -8px -8px 16px #fff;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #051753;
    height: 50px;

    &.disabled {
      color: rgba(136, 143, 167, .45);
    }

    &:hover{box-shadow: 0 0 0 #C9D9E8, 0 0 0 #fff;}
  }

  &--absolute{
    position: fixed;
    bottom: 50px;
    right: 50px;
    padding: 20px 30px;
  }

  &.lang{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg{
      margin-left: 5px;
      margin-right: 5px;
      height: 24px;
      width: 24px;
    }
  }
}

.row_btn {
  padding: 5px;
}

.doc {
  margin: 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8fafc;

  svg {
    width: 32px;
    height: 32px;
  }
}

.icon {
  margin: 0 auto;
  width: 48px;
  height: 48px;
  display: inline-block;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 32px;
    height: 32px;
  }
}

/*layout*/
.wrapper {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding-left: 72px;
  padding-top: 72px;

  @media only screen and (max-width: 767px) {
    padding: 60px 0 72px;
  }
}

/*typographyc*/
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
q,
figure,
figcaption,
blockquote,
cite,
fieldset,
hr {
  margin: 0;
  padding: 0;
  border: none;
}

ul li,
ol li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  transition: 0.25s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:-moz-any-link:focus,
  &:-moz-focusring,
  &::-moz-focus-inner,
  &::-moz-focus-outer {
    outline: 0 !important;
    outline: none;
  }

  &:active {
    outline: none;
  }

  &[href^='tel:'],
  &[href^='mailto:'] {
    white-space: nowrap;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  display: block;
  transition: fill 0.25s, stroke 0.25s;
  font-size: 0;
}

/*form*/
.input {
  /*reset*/
  @include mixins.input-reset;
  /*my styles*/
  //  color: $clr-input;

  /*placeholder*/
  //  &::-webkit-input-placeholder{opacity: 1; color: $clr-placeholder;}
  //  &::-moz-placeholder{opacity: 1; color: $clr-placeholder;}
  //  &:-moz-placeholder{opacity: 1; color: $clr-placeholder;}
  //  &:-ms-input-placeholder{opacity: 1; color: $clr-placeholder;}
  //  &:placeholder{opacity: 1;color: $clr-placeholder;}
  /*webkit autocomplit input*/
  //  &:-webkit-autofill {
  //      -webkit-box-shadow: inset 0 0 0 100px #f9f9f9 !important; /* Цвет фона */
  //      -webkit-text-fill-color: #929292 !important; /* цвет текста */
  //      color: #929292 !important; /* цвет текста */
  //  }
}
//.input:focus:required:valid,
//.textarea:focus:required:invalid{color: $clr-input;}
//.input:required:valid,
//.textarea:required:valid{color: $clr-input;}

.styled-select {
  position: relative;
  text-align: left;

  select {
    display: none;
  }
}

.select-selected {
  background: #fff;
  height: 40px;
  line-height: 38px;
  color: #4b5876;
  padding: 0 32px 0 15px;
  border: 1px solid #bcc2cd;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    position: absolute;
    content: '';
    background: url(../assets/svgSprite/right.svg) center center no-repeat;
    background-size: 100% auto;
    width: 13px;
    height: 11px;
    top: 50%;
    right: 15px;
    margin-top: -5.5px;
  }
}

.select-selected.select-arrow-active:after {
  transform: rotate(180deg);
}

.select-selected.select-arrow-active {
  border-radius: 4px 4px 0 0;
}

.select-items div {
  color: #4b5876;
  font-size: 16px;
  padding: 12px 15px;
  cursor: pointer;
  font-weight: 500;
}

.select-items {
  margin-top: -1px;
  position: absolute;
  background: #f8fafc;
  border: 1px solid #bcc2cd;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 0 0 5px 5px;
  max-height: 250px;
  overflow-y: auto;
}

.select-hide {
  display: none;
}

.select-items div:hover {
  background-color: #9c9c9c;
  color: #fff;
}

div.same-as-selected {
  color: #6b1fb2;
}

@media only screen and (max-width: 767px) {
  .select-selected {
    font-size: 16px;
  }

  .select-items div {
    font-size: 14px;
    padding: 10px 15px;
  }
}

.error {
  color: #cc0000;
  font-size: 12px;
}

/*field*/
.input{
  display: block;
  width: 100%;
  height: 50px;
  outline: none;
  border: 1px solid rgba(5, 23, 83, .15);
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: .2s linear;
  padding: 0 15px;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 16px;
  color: #051753;
}
.input:disabled{background-color: #F1F5F9;}

.label{
  display: block;
  position: relative;
}
.label--icon .input{padding-right: 50px;}
.label-title{
  position: absolute;
  z-index: 3;
  font-weight: 400;
  font-size: 14px;
  color: rgba(5, 23, 83, .25);
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  transition: top .2s linear, color .2s linear, font-size .2s linear;
  padding: 0 6px;
  background-color: #fff;
  white-space: nowrap;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.label__icn{
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label.value .label-title,
.label.focused .label-title{
  top: 0;
  font-size: 12px;
  color: rgba(5, 23, 83, .5);
}
.label.value .input{border-color: rgba(5, 23, 83, .5);}
.label.value .input:disabled{border-color: #F1F5F9;}
.label:hover .input{border-color: #A4D8F7;}

/* tooltip */
.rc-tooltip.rc-tooltip-zoom-appear,
.rc-tooltip.rc-tooltip-zoom-enter {
  opacity: 0;
}
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #051753;
  text-align: left;
  text-decoration: none;
  background-color: #C9D9E8;
  border-radius: 8px;
  min-height: 34px;
  //border: 1px solid #b1b1b1;
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  transform: translate(-50%, 5px);
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #C9D9E8;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #C9D9E8;
  transform: translateX(calc(-100% + 1px));
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #C9D9E8;
  transform: translateX(calc(100% - 1px));
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  transform: translate(-50%, -5px);
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #C9D9E8;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}