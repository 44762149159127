.input-select{
  position: relative;

  select{display: none;}

  .select-selected{
    background: #fff;
    height: 50px;
    line-height: 48px;
    color: #051753;
    padding: 0 50px 0 15px;
    border: 1px solid rgba(5, 23, 83, .5);
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    &:after{
      position: absolute;
      content: '';
      background: url(./dropdown.svg) center center no-repeat;
      background-size: 100% auto;
      width: 8px;
      height: 4px;
      top: 50%;
      right: 22px;
      margin-top: -2px;
    }

    &.select-arrow-active{
      border-color: #A4D8F7;
      border-radius: 4px 4px 0 0;

      &:after {transform: rotate(180deg);}
    }
  }

  .select-items {
    margin-top: -1px;
    position: absolute;
    background: #fff;
    border: 1px solid #A4D8F7;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 0 0 4px 4px;
    max-height: 250px;
    overflow-y: auto;

    div{
      color: #051753;
      font-size: 16px;
      padding: 10px 22px;
      cursor: pointer;
      color: rgba(5, 23, 83, .8);
      transition: color .2s linear;
    }
  }
  .select-hide {display: none;}
  .select-items div:hover, .same-as-selected {
    background-color: #F1F5F9;
    color: #051753;
  }
  div.same-as-selected{color: #051753;}

  .input-select__title{
    position: absolute;
    z-index: 3;
    font-weight: 400;
    font-size: 12px;
    color: rgba(5, 23, 83, .5);
    left: 9px;
    top: 0;
    transform: translateY(-50%);
    transition: top .2s linear, color .2s linear, font-size .2s linear;
    padding: 0 6px;
    background-color: #fff;
    white-space: nowrap;
    max-width: calc(100% - 18px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.error{
    .select-selected{
      border: 1px solid #cc0000;
    }
  }
}