//svg reset
@mixin svg-reset {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
}

//form resets
@mixin btn-reset {
  display: block;
  padding: 0;
  outline: 0 !important;
  outline-color: transparent !important;
  outline-style: none !important;
  border: none;
  background: none;
  box-shadow: none;
  transition: 0.25s ease;
  cursor: pointer;
  border-radius: 0;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::-moz-focus-inner {
    border: 0;
  }

  &:disabled {
    cursor: default;
    opacity: 1;
    user-select: none;
  }
}

@mixin input-reset {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.25s ease;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 0;
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
