.list-table-sec {
  border-top: 1px solid rgba(75, 88, 118, 0.2);
  margin-bottom: 72px;

  @media only screen and (max-width: 1199px) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.table-overlay {
  overflow: auto;
  padding-bottom: 100px;
}

.list-table {
  border-collapse: collapse;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  width: 100%;

  tr td,
  tr th {
    border-bottom: 1px solid rgba(75, 88, 118, 0.2);
    padding: 16px 24px;
    color: #4b5876;
    white-space: nowrap;

    &:first-child {
      padding-left: 72px;
    }

    &:last-child {
      padding-right: 72px;
    }

    &.status {
      text-align: center;

      &.blue {
        color: #2896D7;
      }

      &.green {
        color: #27ae60;
      }

      &.orange {
        color: #f2994a;
      }
    }

    &.empty{
      padding: 0 !important;
    }

    .check{
      align-items: center;
      justify-content: center;


    }

    .check__dop {
      //width: 32px;
      //height: 32px;

      svg {
        //width: 0;
        //height: 0;
        fill: #5df888;
      }
    }

    &.text-center{
      text-align: center;
    }
  }

  tr th {
    padding: 24px;
    opacity: 0.6;
    font-weight: 500;
    text-align: left;

    &:first-child {
      padding-left: 72px;
    }

    &:last-child {
      padding-right: 72px;
    }
  }

  .blue {
    color: #2d9cdb;
  }

  .black {
    color: #4b5876;
  }

  .purple {
    color: #6b1fb2;
  }

  .white {
    color: #bcc2cd;
  }

  .green {
    color: #27ae60;
  }

  .blue{
    color: #2896D7;
  }

  .orange {
    color: #f2994a;
  }

  .styled-select {
    width: 154px;
  }

  .pdf {
    width: 32px;
    height: 32px;
    cursor: pointer;
    fill: transparent;
  }


  .doc {
    width: 32px;
    height: 32px;
    //cursor: pointer;
  }

  .chat {
    width: 32px;
    height: 32px;
    cursor: pointer;
    fill: transparent;
    margin-left: 10px;
  }

  &--center {
    text-align: center;

    tr th {
      text-align: center;
    }

    .styled-select {
      margin: 0 auto;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__column {
    display: flex;
    //flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 1199px) {
    tr td,
    tr th {
      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 1.5;

    tr td,
    tr th {
      padding: 12px 18px;

      &:first-child {
        padding-left: 18px;
      }

      &:last-child {
        padding-right: 18px;
      }
    }
  }
}
