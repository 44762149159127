:root {
    /* Fonts */
    --font-main: 1.6rem/1.2 Lora, serif;
    --font-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Roboto Medium', Arial, sans-serif;

    /* Colors */
    --color-text: #9ea0a5;
    --color-white: #fff;
    --color-background: #fafafa;
    --color-border: #e1dfe2;
    --color-red: #f65460;
    --color-blue: #4da1ff;
    --color-yellow: #d4b021;
    --color-green: #19b59e;
    --color-light-green: #38f08f;
    --color-row: #f6f4f7;
    --color-main: #705485;
    --color-main-hover: #dbd4e1;
    --color-table-brd: #d1d8f5;
    --color-background-preloader: rgba(255,255,255,0.2);
    --color-preloader: transparent;

    /* Shadow */
    --shadow-blc: 0 .6rem 1rem 0 rgba(0, 0, 0, .03);
    --shadow-hover: 0 .3rem .7rem 0 rgba(0, 0, 0, .035);
    --shadow-btn: 0 .4rem .6rem 0 rgba(0, 0, 0, .2);
    --shadow-dashboard: 0 .2rem .5rem 0 rgba(83, 97, 255, .05);
    --shadow-dashboard-hover: .5rem .86rem .95rem .05rem rgba(83, 97, 255, .2);

    /* Gradient */
    --gradient-orange: linear-gradient( 139deg, rgb(247,197,152) 0%, rgb(255,136,134) 100%);
    --gradient-blue: linear-gradient( 139deg, rgb(115,109,255) 0%, rgb(169,127,255) 100%);
    --gradient-viol: linear-gradient( -45deg, rgb(249,180,189) 0%, rgb(149,115,219) 100%);
    --gradient-main: linear-gradient( -45deg, #705485 0%, rgb(149,115,219) 100%);
    --gradient-green: linear-gradient( -44deg, rgb(68,222,197) 0%, rgb(78,188,250) 100%);
}
