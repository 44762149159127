.header {
	border-bottom: 1px solid rgba(75, 88, 118, 0.2);
	height: 72px;
	position: fixed;
	top: 0;
	left: 72px;
	right: 0;
	z-index: 200;
	background-color: #fff;
	padding: 0 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 1199px) {
		padding: 0 32px;
	}

	@media only screen and (max-width: 767px) {
		border-bottom: none;
		border-top: 1px solid rgba(75, 88, 118, 0.2);
		height: 72px;
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		padding: 0;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
}

.header-sub-nav{
	position: absolute;
	background-color: white;
	display: none;
}

.header-nav {
	margin-left: -24px;
	display: flex;
	flex: 1;

	ul {
		display: flex;
		align-items: center;
		justify-content: stretch;
		flex: 1;

		li{
			position: relative;

			&:hover{
				.header-sub-nav{
					display: block;
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		margin: 0;
		width: 75%;
		height: 100%;
		flex-shrink: 0;

		ul {
			height: 100%;

			li {
				height: 100%;
				width: calc(100% / 3);
				border-right: 1px solid rgba(75, 88, 118, 0.2);
			}
		}
	}

	&__max{
		display: flex;
		flex: 1;
		justify-content: flex-end;
		padding-right: 10px;
	}
}

.header-nav-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 24px;
	line-height: 70px;
	min-width: 227px;
	font-size: 18px;
	color: #4b5876;
	position: relative;
	white-space: nowrap;

	&::after {
		content: '';
		display: block;
		bottom: -1px;
		position: absolute;
		left: 0;
		right: 0;
		height: 1px;
		background-color: #6b1fb2;
		opacity: 0;
		transition: 0.25s opacity;
	}

	&.active,
	&:hover {
		background-color: rgba(107, 31, 178, 0.05);

		&::after {
			opacity: 1;
		}

		.header-nav-item__icn svg {
			stroke: #6b1fb2;
		}
	}

	&__icn {
		display: block;
		width: 24px;
		height: 24px;
		margin-right: 16px;

		svg {
			display: block;
			width: 100%;
			height: 100%;
			stroke: #4b5876;
			fill: transparent;
			stroke-width: 0;
		}
	}

	@media only screen and (max-width: 1199px) {
		min-width: unset;
		font-size: 16px;
	}

	@media only screen and (max-width: 767px) {
		flex-direction: column;
		justify-content: center;
		padding: 0;
		line-height: 1.2;
		font-size: 13px;
		text-align: center;
		width: 100%;
		height: 100%;

		&__icn {
			margin: 0 0 10px;

			svg {
				display: block;
				width: 100%;
				height: 100%;
				stroke: #4b5876;
				fill: transparent;
			}
		}

		&::after {
			bottom: 0;
		}
	}
}

.profile-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row-reverse;

	&__ava {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		margin-left: 24px;
	}

	&__name {
		color: #4b5876;
		font-size: 18px;
		line-height: 24px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media only screen and (max-width: 1199px) {
		&__name {
			font-size: 16px;
		}
	}

	@media only screen and (max-width: 767px) {
		flex-direction: column;
		width: 25%;
		flex-shrink: 0;

		&__ava {
			width: 50px;
			height: 50px;
			margin: 0;
		}

		&__name {
			display: none;
		}
	}
}
