.codes-page{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4{
    margin-top: 20px;
  }
}