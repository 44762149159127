@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'), url('./fonts/Gilroy-Regular.woff2') format('woff2'),
  url('./fonts/Gilroy-Regular.woff') format('woff'), url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'), url('./fonts/Gilroy-Medium.woff2') format('woff2'),
  url('./fonts/Gilroy-Medium.woff') format('woff'), url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Semibold'), local('Gilroy-Semibold'), url('./fonts/Gilroy-Semibold.woff2') format('woff2'),
  url('./fonts/Gilroy-Semibold.woff') format('woff'), url('./fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
