.toolbar {
	position: fixed;
	z-index: 700;
	top: 0;
	right: 0;
	bottom: 0;
	width: 414px;
	background: #fff;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: box-shadow 0.35s linear, transform 0.35s linear;
	transform: translateX(101%);
	overflow-y: auto;

	&.open {
		box-shadow: -5px 0 4px rgba(0, 0, 0, 0.1);
		transform: translateX(0);
	}

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: #fff;
		border: 1px solid #fff;
		box-shadow: none;
		outline: none;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #5186ff;
		border-radius: 4px;
		border: 1px solid #fff;

		&:hover {
			background: #5186ff;
		}
	}

	&__wrap {
		width: 100%;
		height: 98vh;
	}

	@media only screen and (max-width: 767px) {
		width: 260px;

		&__wrap {
			padding: 20px 15px;
		}
	}
}

.toolbar-form {
	height: 100%;

	&__fieldset {
		display: block;
		width: 100%;
		margin-bottom: 18px;

		&-wrap {
			padding-left: 20px;
		}

		&.provider-selector {
			.drop-group__hide {
				width: 270px;
			}

			.right-drop {
				.drop-group__hide {
					left: -135px;
				}
			}
		}

		&-row {
			display: flex;
			flex-direction: row;

			button {
				margin: 0 10px;
			}
		}

		&.one {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	&__legend {
		margin-bottom: 20px;
		font-size: 16px;
		line-height: 20px;
		color: #34375c;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__upload {
		margin-bottom: 7px;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 8px;
		width: 276px;
	}

	&__col {
		width: calc(50% - 14px);
	}

	&__checklist {
		padding-left: 9px;

		&-main {
			padding: 10px 14px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			border: 1px solid #e9eff4;
			border-radius: 4px;
			margin-bottom: 7px;
			flex-wrap: wrap;
		}

		&-col {
			width: 33.333%;
		}

		&-group {
			margin-top: 4px;
			padding-left: 14px;
			flex-wrap: wrap;
		}

		&-sub {
			padding-left: 19px;
		}
	}

	&__label {
		color: #606470;
		font-size: 14px;
		line-height: 1.2;
		padding: 6px 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.react-date-picker {
			margin-left: 33px;
		}
	}

	&__submit{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	@media only screen and (max-width: 767px) {
		&__fieldset {
			max-width: 224px;
			margin-bottom: 24px;

			&-wrap {
				padding-left: 0;
			}
		}

		&__legend {
			margin-bottom: 12px;
		}

		&__checklist {
			padding-left: 0;

			&-main {
				display: block;
			}

			&-col {
				width: 100%;
			}
		}

		&__row {
			display: block;
			margin-bottom: 0;
		}

		&__col {
			width: 100%;
			margin-bottom: 8px;
		}
	}

	.styled-select {
		.styled-select__item {
			color: #606470;
			font-size: 14px;
		}
	}
}

.toolbar-form-table {
	margin: 12px 0 20px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-left: 9px;

	&__col {
		padding-left: 14px;
		width: 50%;

		table {
			tr {
				td {
					padding: 2px 0;
					text-align: left;
					font-size: 14px;
					line-height: 18px;
					color: #606470;

					&:first-child {
						color: rgba(96, 100, 112, 0.5);
					}
				}

				&:last-child {
					td {
						&:first-child {
							color: #606470;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		padding-left: 0;
		width: 224px;
		&__col {
			padding-left: 0;
		}
	}
}

.toolbar-form-comment {
	margin: 20px 0 12px;
	padding-left: 9px;

	@media only screen and (max-width: 767px) {
		padding-left: 0;
	}
}