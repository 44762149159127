@use './mixins';

.table {
  &__overlay {
    overflow: auto;
  }

  &__wrap {
    width: max-content;
  }

  &__thead {
    margin-bottom: 24px;
  }

  &__tr {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__th,
  &__td {
    flex-grow: 1;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__th {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    color: #4b5876;
    opacity: 0.6;
  }

  &__tbody {
    .table__tr {
      margin-bottom: 3px;
      border: 1px solid rgba(188, 194, 205, 0.5);
      border-radius: 8px;
    }
  }

  &__td {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4b5876;

    time {
      opacity: 0.4;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 1439px) {
    width: calc(100% + 60px);
    margin-left: -30px;

    &__wrap {
      padding: 0 30px;
      min-width: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    width: calc(100% + 40px);
    margin-left: -20px;

    &__wrap {
      padding: 0 20px;
    }
  }
}

.doc {
  display: block;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  border-radius: 0;

  svg {
    @include mixins.svg-reset;
    fill: transparent;
  }
}

.shipments-table-mark {
  width: 108px;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  line-height: 32px;

  &__icn {
    display: block;
    margin-right: 4px;

    svg {
      @include mixins.svg-reset;
      max-width: 100%;
      width: 16px;
      height: 16px;
    }
  }

  &--no {
    border-color: #f2994a;
    color: #f2994a;
  }

  &--is {
    border-color: #27ae60;
    color: #27ae60;
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 116px;

  &__counter {
    width: 28px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 2;
    color: #4b5876;
  }

  &__wrap {
    flex-grow: 1;
    position: relative;
    height: 8px;
    border-radius: 4px;
  }

  &__line {
    height: 8px;
    background: linear-gradient(90deg, #7c63cc 0%, rgba(124, 99, 204, 0.7) 100%);
  }

  &__dot {
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #dde0e6;
    transform: translateX(-50%);

    &.active {
      background-color: #7c63cc;
    }
  }
}

.shipments-table {
  &__date {
    width: 87px;
    max-width: 87px;
    flex-shrink: 0;
  }

  &__documents {
    width: 105px;
    max-width: 105px;
    flex-shrink: 0;
  }

  &__calculation {
    max-width: 140px;
    width: 140px;
    flex-shrink: 0;
  }

  &__logistic {
    width: 140px;
    max-width: 140px;
    flex-shrink: 0;
  }

  &__steps {
    width: 148px;
    max-width: 148px;
    flex-shrink: 0;
  }

  &__name {
    width: 97px;
    max-width: 97px;
    flex-shrink: 0;
  }

  &__autor {
    width: 148px;
    max-width: 148px;
    flex-shrink: 0;
  }

  &__provider {
    flex-grow: 2;
  }
}
