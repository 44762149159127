.report-page {
  padding: 20px;

  label {
    width: 200px;

    input {
      display: none;
    }
  }
}
