.user-page {
	.button {
		cursor: pointer;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;

		.icon_button {
			cursor: pointer;
			margin-left: 10px;
			width: 24px;
			height: 24px;
		}

      .btn{
        margin-right: 10px;
      }
	}

	&__active {
		width: 32px;
		height: 32px;
	}

	.check__dop {
		//width: 32px;
		//height: 32px;

		svg {
			width: 0;
			height: 0;
		}
	}

  .search-sec{
    .btn{
      margin-left: 10px;
    }
    &__wrap{
      padding: 15px;
      min-height: unset;
    }
    .input-search{
      width: 100%;
    }
  }

  &__add{
    display: flex;
    flex-direction: row;
    align-items: center;
    //background-color: #fff;
    //padding: 15px;
    padding: 15px;

    &--title, .input-select, .input-text, .input-suggest{
      margin-right: 10px;
      min-width: 150px;
    }

    .btn{
      outline: 0!important;
      outline-color: transparent!important;
      outline-style: none!important;
      border: none;
      background: none;
      transition: .25s ease;
      cursor: pointer;
      white-space: nowrap;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 9px;
      gap: 8px;
      background-color: #F1F5F9;
      box-shadow: 2px 2px 8px #C9D9E8, -8px -8px 16px #fff;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #051753;
      height: 45px;

      &:hover{box-shadow: 0 0 0 #C9D9E8, 0 0 0 #fff;}

      svg{
        stroke: #ccc;
        width: 32px;
        height: 32px;
      }
    }
  }

  .list-table tr td{
    white-space: wrap;
    div{
      white-space: wrap;
    }
  }
}
