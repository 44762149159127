@use '../../scss/mixins';

.auth-page {
  width: 100vw;
  height: 100vh;
  background: url(bg.jpg) center center no-repeat;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    background: url(stars.png) center center no-repeat;
    background-size: contain;
    width: 42.4rem;
    height: 64.8rem;
    margin-top: -32.4rem;
  }

  &__wrap {
    max-width: 112rem;
    margin: 0 auto;
    padding: 0 4rem;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    @include mixins.flex-center-center;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    label {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      input {
        width: 400px;
        padding: 10px;
        font-size: 16px;
      }
    }

    button {
      margin-top: 20px;
      width: 190px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      background: #5186ff;
      border: 1px solid rgba(81, 134, 255, 0.7);
      border-radius: 2px;
      font-size: 18px;
    }
  }

  &__row {
    width: 100%;

    @include mixins.flex-center-between;
  }

  &__signin {
    display: block;
    padding: 0;
    outline: transparent none 0 !important;
    border: none;
    transition: box-shadow 0.25s ease;
    cursor: pointer;
    white-space: nowrap;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 21.8rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    border-radius: 2px;
    background-color: var(--color-main);
    color: var(--color-white);
    box-shadow: 0 0 0 0 rgba(52, 77, 178, 0);
    font-size: 1.8rem;
    margin-right: 20px;

    &:hover {
      box-shadow: 0.983rem 0.6883rem 3.29rem 0.21rem rgba(52, 77, 178, 0.45);
    }
  }

  &__logo {
    display: block;
    width: 16rem;
    position: absolute;
    top: 4.7rem;
    left: 4rem;
  }

  &__main {
    width: 50%;
    padding-right: 4rem;
  }

  &__title {
    //font-style: italic;
    color: var(--color-white);
    font-size: 3rem;
    margin-bottom: 6.4rem;
    font-weight: 400;
  }

  &__pic {
    width: 50%;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 767px) {
    margin-left: -6rem;

    &__wrap {
      flex-direction: column;
      justify-content: center;
    }

    &__row {
      display: block;
    }

    &__signin {
      margin: 0 auto;
    }

    &__logo {
      position: static;
      margin: 0 auto 4rem;
    }

    &__main {
      width: 100%;
      padding-right: 0;
    }

    &__title {
      text-align: center;
      font-size: 3rem;
      margin-bottom: 4rem;
    }

    &__pic {
      display: none;
    }
  }
}

.sign-form__input {
  font-size: 16px !important;
}
